@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=PT+Sans+Caption:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

#landing img {
	width:  100%!important;
}

#landing {
	height: 100vh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding-top: 10rem;
	padding-bottom: 10rem;

}

.carousel-it {
	display: flex;
	justify-content: center;
	margin-left: auto!important;
	margin-right: auto!important;
}

.carousel-capt {
	height: fit-content!important;
	background-color: rgba(0, 0, 0, 0.25)!important;
	padding: 2rem;
	width: max-content;
	color: white;
	margin: 3rem;
}

.landingImage {
	width:  100%;
	height: 75%;
}

.landingTitle {
	font-weight: 800;
	font-size: 3.3rem;
	margin-left: auto;
	margin-right: auto;
}

.modal-dialog {
	max-width: 100%;
}

.gallery {
	padding-top: 10rem;
	padding-bottom: 10rem;
}

/*---------------------------------*/

#about {
	text-align: center;
	background-color: #FCA311;
	padding-top: 10rem;
	padding-bottom: 10rem;
}

.aboutTitle {
	text-transform: uppercase;
	font-weight: 800;
}


.aboutCaption {
	width: 75%;
	color: #e5e5e5;
	margin-left: auto;
	margin-right: auto;
}



/*---------------------------------*/

#services {
	margin-left: auto;
	margin-right: auto;
	padding-top: 10rem;
	padding-bottom: 10rem;

}


.serviceHeading {
	text-align: center;
}

.serviceTitle {
	text-transform: uppercase;
	font-weight: 800;
}

.serviceCaption {
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	color: #171717;
	margin-bottom: 4rem;
}

.serviceButton {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}


#cards {
	width: 100%;
	height: 100%;
	margin-bottom: 2rem;

}


.cardImage {
	width: 100%;
	height: 50%;
	margin-bottom: 1.5rem;
}

.cardSection {
	width: 100%;
	height: 100%;
	padding-top: 2rem;
}

.cardTitle {
	width: 100%;
	text-transform: uppercase;
	font-weight: 800;
	font-family: 'Ubuntu', sans-serif;
	margin-bottom: 2rem;
}


.cardCaption {
	width: 100%;
	color: #171717;
}


/*---------------------------------*/


#contact {
	background-color: #14213d;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10rem;
	padding-bottom: 10rem;
	text-align: center;
	width: 100%;
}


.contactTitle {
	text-transform: uppercase;
	font-weight: 800;
	color:  #e5e5e5;
}

.contactCaption {
	width: 75%;
	color: #e5e5e5;
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
}

.contactDivider {
	color: white;
}

.contactForm {
	width: 50%;
	margin-right: auto;
	margin-left: auto;
	color: white;
}

.form-label {
	margin-right: auto!important;
}



/* ----------------------------------------- */


#faq {
	background-color: #14213d;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10rem;
	padding-bottom: 5rem;
	width: 100%;
	text-align: center;
}

.faqHeading {
	text-align: center;
	font-weight: 800;
	color: white;
	text-transform: uppercase;
}


.faqSection {
	margin-top: 2rem;
}

.faqQuestion {
	color: white;
}

.faqAnswer {
	color: gray;
}







/* ----------------------------------------- */

@media (max-width: 576px) {

	.landingTitle {
		font-weight: 800;
		font-size: 2.0rem;
		margin-left: auto;
		margin-right: auto;
	}



	.carousel-image {
		height: 100%!important;
		width: 100%;
	}


	#landing {
		height: 100%;
		text-align: center;
		padding-bottom: 10rem;
	}

	.landingImage {
		display: none;
	}

	.serviceCaption {
		width: 100%;
	}

	#services {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}


	.cardTitle {
		width: 100%;
	}

	.cardSection {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;

	}

	.cardCaption {
		width: 100%;
	}

	#contact {
		width:  100%;
	}

	#about {
		width: 100%;
		padding-bottom: 10rem;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.aboutTitle {
		width: 100%;
	}


	.aboutCaption {
		width: 100%;
	}


	.contactForm {
		width: 100%!important;
	}

	.form-control {
		width: 100%;
	}

}

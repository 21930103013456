body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background-color: #f3f5f8;
}

hr {
	width: 2rem;
	margin-left: auto;
	margin-right: auto;
}

p {
	font-family: 'Lato', sans-serif;
}

a {
	text-decoration: none;
}

a:hover {
	color: inherit;
}

::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-track {
    border-radius: 1rem;
  	background-color: rgba(0,0,0,0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

/* ------------------------------- */


.navbar-nav {
	margin-left: auto;
	justify-content: space-between;
	margin-right: 2rem;
}

.nav-link {
	margin-left: 2rem;
	margin-right: 2rem;
}

.navbar-brand {
	font-weight: 800;
	font-size: 2.1rem;
}

/* ------------------------------- */

#footer {
	padding: 2rem;
	background-color: #151414;
}

.footerCompany {
	margin-top: 2rem;
	color: #E5E5E5;
	text-transform: uppercase;
	font-weight: 700;

}

.footerLink {
	text-decoration: none;
	color: #ffb703;
}

.footerLink:hover {
	color: #ffb703;
}

.footerCompanyTag {
	color: #ffffff;
}


.footerTag {
	color: #E5E5E5;
	font-style: italic;
}


.footerIconSection {
	color: white;
	margin-top: 4rem;
}

.footerIcons {
	margin-right: 1rem;
	color: #FB8500;
}

.footerIcons:hover {
	color: #FFB703;
}
